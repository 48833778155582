html{
  background-color: #111012;
}

body{
  background-color: #111012;
}

.App {
  background-color: #111012;
  font-family: 'Consolas', 'Courier New', Courier, monospace;
  width: 100%;
}

#banner{
  height: 20vh;
  width: 100%;
  background-image: url('assets/biglogo.svg');
  mix-blend-mode: screen;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  margin: auto auto
}

nav{
  background-color: #111012aa;
  height: 3rem;
}

.nav .item{
  color: lightgrey;
}

.nav a.active {
  color: indianred;
}

#brand {
  height: 2rem;
  position: fixed;
  top: .5rem;
  left: .5rem;
  z-index: 2;
}

.mySwiper{
  max-width: 150vh;
  padding: 3rem;
  /* border-top: 3rem solid #111012; */
}

.swiper-slide img {
  width: 100%;
}

.slideWords{
  font-family: 'Consolas', 'Courier New', Courier, monospace;
  width: 105%;
  position: absolute;
  top: 60%;
  left: 25%;
  transform: translate(0, -50%);
  color: transparent;
  padding: 2rem;
  transition: 0.5s ease;
  max-height: 100%;
}

.slideWords a, .slideWords a:hover{
  display: none;
}

.swiper-slide-active .slideWords {
  background-color: #232125da;
  color: white;
  overflow: auto;
  scrollbar-color: #111012aa #23212300;
}

.swiper-slide-active .slideWords::-webkit-scrollbar{
  background-color: #23212500;
  width: 15px;
}

.swiper-slide-active .slideWords::-webkit-scrollbar-thumb{
  background-color: #111012aa;
  border-radius: 15px;  
  background-clip: content-box;
  border: 4px solid transparent;
}

.swiper-slide-active a, .swiper-slide-active a:hover{
  display: inline-block;
}

#about, #contact, #skillset {
  padding: 2rem 3rem;
  border-top: 3rem solid #111012;
}

#about, #contact{
  background-color: #232125da;
}

#skillset{
  background-color: #fffb;
}

.skillsIcons{
  height: 7rem;
  padding: 1rem;
}

.skillsIcons img{
  height: 100%;
}

.skillsHover{
  font-family: 'Consolas';
  color: lightgrey;
  background-color: #232125da;
  padding: .5em 1em;
  border-radius: 1em;
  border: thin solid slategrey;
}

@media only screen and (min-width: 768px){
  #about, #contact, #skillset {
    margin: 7rem 10rem;
  }
}

@media screen and (max-width: 768px){
  
  .nav {
    flex-direction: column;
  }
  
  #collapsible {
    background-color: #111012;
  }

  .slideWords{
    width: 100vw;
    max-width: 150vh;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
  }

  .swiper-slide-active #arrowDown{
    float: right;
    width: 3rem;
    height: 3rem;
    background-image: url('./assets/arrow-down.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 100%;
  }

  #contactTable th {
    display: none;
  }
}